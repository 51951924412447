// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-careers-front-end-developer-jsx": () => import("./../src/pages/careers/front-end-developer.jsx" /* webpackChunkName: "component---src-pages-careers-front-end-developer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-pricing-jsx": () => import("./../src/templates/pricing.jsx" /* webpackChunkName: "component---src-templates-pricing-jsx" */),
  "component---src-templates-white-paper-jsx": () => import("./../src/templates/white-paper.jsx" /* webpackChunkName: "component---src-templates-white-paper-jsx" */)
}

